<!--
 * @Description: 
 * @Autor: zhangzhang
 * @Date: 2021-06-10 16:00:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-02-25 12:51:28
-->
<template>
  <SideBar :groups="groups"></SideBar>
</template>

<script>
import SideBar from '@/components/SideBar'
export default {
  components: {
    SideBar
  },

  data() {
    return {
      groups: [
        {
          label: '系统配置',
          list: [
            {
              name: '客户联系',
              path: '/admin/system/contact'
            },
            {
              name: '系统参数',
              path: '/admin/system/system'
            },
            {
              name: '微信参数',
              path: '/admin/system/wxInfo'
            }
            // {
            //   name: '域名说明',
            //   path: '/admin/system/explain'
            // }
          ]
        },
        {
          label: '管理员',
          list: [
            {
              name: '分组管理',
              path: '/admin/system/roles'
            },
            {
              name: '成员管理',
              path: '/admin/system/admin'
            }
          ]
        }
      ]
    }
  }
}
</script>
